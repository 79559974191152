import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class FeedService {
    constructor(
        public http: HttpClient,
        public route: Router,
    ) {}

    getRssContent(): Observable<string> {
        const pressroomId = this.route.parseUrl(this.route.url).root.children
            .primary.segments[1].path;
        const url = `${environment.url}api/v1/public/rss/${pressroomId}`;
        return this.http.get(url, {
            responseType: 'text',
        }) as Observable<string>;
    }
}
