// Service to fetch content for the news article
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

export interface ArticleQueryParams {
    page: number;
    itemsPerPage: number;
    searchText?: string;
    month?: number | null;
    year?: number | null;
    isVisible?: boolean | null | number;
    isStory?: boolean | null;
    filterCategories?: any | null;
}

@Injectable({
    providedIn: 'root',
})
export class NewsService {
    viewFullArticle = new BehaviorSubject<any>(null);
    constructor(
        public http: HttpClient,
        public route: Router,
    ) {}

    getArticles(reqBody: ArticleQueryParams): Observable<any> {
        const pressroomId = this.route.parseUrl(this.route.url).root.children
            .primary.segments[1].path;
        const url = `${environment.url}api/v1/public/press-releases/${pressroomId}`;

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });

        return this.http.post<any>(url, reqBody, { headers }).pipe(
            catchError((error) => {
                console.error('Error fetching articles:', error);
                return throwError(error);
            }),
        );
    }
}
