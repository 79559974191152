import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MaterialModule } from '../../material.module';

@Component({
    selector: 'app-category-chip',
    templateUrl: './category-chip.component.html',
    styleUrl: './category-chip.component.scss',
})
export class CategoryChipComponent {
    @Input() categoryId: any;
    @Input() categories: any;
    @Input() textColor: any;
    @Input() bgColor: any;

    constructor() {}

    getCategoryName(categoryId: any): string {
        if (!this.categories) {
            return '';
        }
        const category = this.categories.find(
            (cat: any) => cat.categoryId === categoryId,
        );
        return category ? category.categoryName.trim() : '';
    }
}
