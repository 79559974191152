<h1 mat-dialog-title>
    {{ title }}
</h1>
<div style="margin-left: 5px">
    <div
        *ngIf="htmlMessage; else standardMessage"
        mat-dialog-content
        [innerHTML]="htmlMessage | sanitizeHtml: 'html'"
    ></div>
    <ng-template #standardMessage>
        <div mat-dialog-content>
            <p>{{ message }}</p>
        </div>
    </ng-template>
</div>
<div class="actions">
    <div mat-dialog-actions>
        <button mat-raised-button [color]="confirmColor" (click)="onConfirm()">
            {{ confirmText }}
        </button>
        <button mat-button (click)="onCancel()">{{ cancelText }}</button>
    </div>
</div>
