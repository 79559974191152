import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Login } from './../auth/auth.model.definition';
import { AuthService } from './../auth/auth.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import jwtDecode from 'jwt-decode';
import {
    LocalStorageService,
    ACCESS_KEYS,
} from '../shared/services/local-storage.service';
import { AppService } from '../app.service';

@Injectable({
    providedIn: 'root',
})
export class LoginService {
    userInfo = new BehaviorSubject<any>(null);
    accountInfo = new BehaviorSubject<any>(null);
    accountMenu = new BehaviorSubject<any>(null);
    blumepressId!: string | null;
    authToken!: string | null;
    constructor(
        public http: HttpClient,
        public auth: AuthService,
        public route: Router,
        private localStorageService: LocalStorageService,
        private appService: AppService,
    ) {
        this.blumepressId = this.localStorageService.getItem(
            ACCESS_KEYS.BLUMEPRESS_ID,
        );
        this.authToken = this.localStorageService.getItem(
            ACCESS_KEYS.AUTH_TOKEN,
        );
    }

    getDomainPressroomId(hostName: any) {
        return new Promise((resolve, reject) => {
            this.http
                .get(`${environment.url}api/v1/public/domain/${hostName}`)
                .subscribe((response) => {
                    resolve(response);
                });
        });
    }

    loginUser(userEmail: string, userPassword: string): Observable<Login> {
        const request = {
            email: userEmail,
            password: userPassword,
        };
        return this.http
            .post<Login>(`${environment.url}api/v1/identity`, request, {
                responseType: 'json',
            })
            .pipe(
                tap((response: any) => {
                    if (response) {
                        if (response.success === true && response.token) {
                            this.localStorageService.setItem(
                                ACCESS_KEYS.AUTH_TOKEN,
                                response.token,
                            );
                            this.appService.setAuthToken(response.token);

                            this.blumepressId = userEmail;
                            this.localStorageService.setItem(
                                ACCESS_KEYS.BLUMEPRESS_ID,
                                userEmail,
                            );
                            this.localStorageService.setItem(
                                ACCESS_KEYS.CURRENT_PRESSROOM,
                                'noPressroom',
                            );
                            this.authToken = response.token;
                            let jwtDecoded: any = jwtDecode(
                                this.authToken || '',
                            );
                            this.localStorageService.setItem(
                                ACCESS_KEYS.CURRENT_ACCOUNT,
                                jwtDecoded.user.accountId,
                            );
                        }
                    }
                }),
            );
    }

    logout() {
        this.localStorageService.removeItem(ACCESS_KEYS.AUTH_TOKEN);
        this.localStorageService.removeItem(ACCESS_KEYS.BLUMEPRESS_ID);
        this.localStorageService.removeItem(ACCESS_KEYS.CURRENT_ACCOUNT);
        this.localStorageService.removeItem(ACCESS_KEYS.CURRENT_PRESSROOM);

        this.route.navigate(['login']);
    }

    fetchUserInfo(
        accountChange: any,
        pressroomId: string,
        newAccountId: string,
    ) {
        return new Promise((resolve, reject) => {
            if (!accountChange) {
                let jwtDecoded: any = jwtDecode(this.authToken || '');
                let accountId = jwtDecoded.user.accountId;
                this.http
                    .get(
                        `${environment.url}api/v1/accounts/user/${accountId}/${this.blumepressId}`,
                    )
                    .subscribe((response: any) => {
                        response.users.forEach(
                            (user: {
                                email: string | null;
                                pressrooms: string | any[];
                            }) => {
                                if (user.email === this.blumepressId) {
                                    this.updateUserInfo(user);
                                    this.fetchAccountList(accountId);
                                    if (user.pressrooms.length > 0) {
                                        this.localStorageService.setItem(
                                            ACCESS_KEYS.CURRENT_PRESSROOM,
                                            user.pressrooms[0].id,
                                        );
                                    } else {
                                        this.localStorageService.setItem(
                                            ACCESS_KEYS.CURRENT_PRESSROOM,
                                            'noPressroom',
                                        );
                                    }
                                    this.localStorageService.setItem(
                                        ACCESS_KEYS.CURRENT_ACCOUNT,
                                        response._id,
                                    );
                                }
                            },
                        );
                        resolve(response);
                    });
            } else {
                this.http
                    .get(
                        `${environment.url}api/v1/accounts/user/${newAccountId}/${this.blumepressId}`,
                    )
                    .subscribe((response: any) => {
                        response.users.forEach(
                            (user: { email: string | null }) => {
                                if (user.email === this.blumepressId) {
                                    this.updateUserInfo(user);
                                    this.fetchAccountList(newAccountId);
                                    this.localStorageService.setItem(
                                        ACCESS_KEYS.CURRENT_PRESSROOM,
                                        pressroomId,
                                    );
                                    this.localStorageService.setItem(
                                        ACCESS_KEYS.CURRENT_ACCOUNT,
                                        newAccountId,
                                    );
                                }
                            },
                        );
                        resolve(response.response);
                    });
            }
        });
    }

    updateUserInfo(userInfo: unknown) {
        return new Promise((resolve, reject) => {
            try {
                this.userInfo.next(userInfo);
                resolve(userInfo);
            } catch (error) {
                reject(false);
            }
        });
    }

    // Account GET: Via Email
    updateAccountList() {
        return new Promise((resolve, reject) => {
            this.http
                .get(
                    `${environment.url}api/v1/accounts/list/${this.blumepressId}`,
                )
                .subscribe((response) => {
                    this.accountMenu.next(response);
                    resolve(response);
                });
        });
    }

    // INFO by ID
    fetchAccountList(accountId: string) {
        return new Promise((resolve, reject) => {
            this.http
                .get(`${environment.url}api/v1/accounts/info/${accountId}`)
                .subscribe(
                    (response: any) => {
                        this.accountInfo.next(response);
                        resolve(response);
                    },
                    (error: any) => {
                        reject(false);
                    },
                );
        });
    }
}
