import { environment } from '../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../auth/auth.service';
import { LoginService } from '../../login/login.service';
import { SnackbarService } from '../../shared/services/snackbar.service';
import { firstValueFrom } from 'rxjs';
import {
    ACCESS_KEYS,
    LocalStorageService,
} from '../../shared/services/local-storage.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
    resetPasswordForm!: FormGroup;
    disableCancel!: boolean;
    constructor(
        private dialogRef: MatDialogRef<ResetPasswordComponent>,
        private snackBar: SnackbarService,
        private http: HttpClient,
        private authService: AuthService,
        private loginService: LoginService,
        private localStorageService: LocalStorageService,
    ) {}

    ngOnInit() {
        this.disableCancel = this.authService.isPasswordUpdated();
        this.resetPasswordForm = new FormGroup({
            currentPassword: new FormControl(null, Validators.required),
            newPassword: new FormControl(null, Validators.required),
            retypeNewPassword: new FormControl(null, Validators.required),
        });
    }

    onSubmit() {
        if (!this.resetPasswordForm.valid) {
            this.snackBar.showMessageWithDelay(
                'Please fill out all fields',
                2000,
            );
        } else if (
            this.resetPasswordForm.value.newPassword !==
            this.resetPasswordForm.value.retypeNewPassword
        ) {
            this.snackBar.showMessageWithDelay(
                'New Passwords do not match',
                2000,
            );
        } else {
            this.resetPassword(
                this.resetPasswordForm.value.currentPassword,
                this.resetPasswordForm.value.newPassword,
            )
                .then((result) => {
                    this.snackBar.showMessageWithDelay(
                        'Password Updated',
                        2000,
                    );
                    this.dialogRef.close(true);
                    this.loginService.logout();
                })
                .catch((err) => {
                    this.snackBar.showMessageWithDelay(
                        err.error.response,
                        2000,
                    );
                });
        }
    }

    onCancel() {
        this.dialogRef.close(false);
    }

    resetPassword(oldPassword: string, newPassword: string): Promise<any> {
        const email = this.localStorageService.getItem(
            ACCESS_KEYS.BLUMEPRESS_ID,
        );
        return firstValueFrom(
            this.http.put(`${environment.url}api/v1/accounts/resetPassword`, {
                email,
                oldPassword,
                newPassword,
            }),
        );
    }
}
