import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ConfirmDialogOptions {
    title: string;
    message: string;
    htmlMessage?: string;
    cancelText?: string;
    confirmText?: string;
    confirmColor?: 'primary' | 'warn';
}

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrl: './confirm-dialog.component.scss',
})
export class ConfirmDialogComponent implements ConfirmDialogOptions {
    title: string;
    message: string;
    htmlMessage?: string;
    cancelText?: string;
    confirmText?: string;
    confirmColor: 'primary' | 'warn';

    constructor(
        private dialogRef: MatDialogRef<ConfirmDialogComponent, boolean>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogOptions,
    ) {
        this.title = data.title;
        this.message = data.message;
        this.htmlMessage = data.htmlMessage || '';
        this.cancelText = data.cancelText || 'No';
        this.confirmText = data.confirmText || 'Yes';
        this.confirmColor = data.confirmColor || 'primary';
    }

    onCancel() {
        this.dialogRef.close(false);
    }

    onConfirm() {
        this.dialogRef.close(true);
    }
}
