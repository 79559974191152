import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { SnackbarService } from '../../shared/services/snackbar.service';
import { Title } from '@angular/platform-browser';
import {
    ACCESS_KEYS,
    LocalStorageService,
} from '../../shared/services/local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class WebPageConfigService {
    currentConfig = new BehaviorSubject<any>(null);
    pressroomId!: string;
    constructor(
        private http: HttpClient,
        private snackBar: SnackbarService,
        private titleService: Title,
        private localStorageService: LocalStorageService,
    ) {
        this.pressroomId = this.localStorageService.getItem(
            ACCESS_KEYS.CURRENT_PRESSROOM,
        );
    }

    async getConfig(pressroomId: string): Promise<any> {
        try {
            const response: any = await firstValueFrom(
                this.http.get(
                    `${environment.url}api/v1/public/website-config/${pressroomId}`,
                ),
            );
            this.titleService.setTitle(response.response.name);
            return response.response.webConfig;
        } catch (error) {
            if (error instanceof HttpErrorResponse) {
                const errorMessage =
                    error.error.message || 'Failed to load pressroom!';
                this.snackBar.showMessageWithDelay(errorMessage, 2000);
                console.error('Error response:', error.error);
            } else {
                this.snackBar.showMessageWithDelay(
                    'Oops Something went wrong.',
                    2000,
                );
                console.error('Unexpected error:', error);
            }
        }
    }

    async editConfig(
        webConfig: any,
        images: any,
        galleryConfig: any,
    ): Promise<any> {
        try {
            webConfig.images = images;
            webConfig.gallaryConfig = galleryConfig;

            const response = await firstValueFrom(
                this.http.put(
                    `${environment.url}api/v1/web-config/${this.pressroomId}`,
                    webConfig,
                ),
            );
            this.snackBar.showMessageWithDelay(
                'Configuration updated successfully!',
                3000,
            );
            return response; // Optionally return the response if needed
        } catch (error) {
            if (error instanceof HttpErrorResponse) {
                const errorMessage =
                    error.error.message ||
                    'Failed to update Website Configuration';
                this.snackBar.showMessageWithDelay(errorMessage, 2000);
            } else {
                // Log unexpected errors
                console.error('Unexpected Error:', error);
                this.snackBar.showMessageWithDelay(
                    'Oops Something went wrong.',
                    2000,
                );
            }
        }
    }

    async removeGalleryImage(imageLink: any): Promise<any> {
        try {
            const imageToDelete = {
                item: imageLink,
                pressroomId: this.pressroomId,
            };

            const response = await firstValueFrom(
                this.http.post(
                    `${environment.url}api/v1/web-config/remove-media-item`,
                    imageToDelete,
                ),
            );

            return response;
        } catch (error) {
            if (error instanceof HttpErrorResponse) {
                const errorMessage =
                    error.error.message || 'Failed to update Web Configuration';
                this.snackBar.showMessageWithDelay(errorMessage, 2000);
                console.error('HTTP Error:', error);
            } else {
                console.error('Unexpected Error:', error);
                this.snackBar.showMessageWithDelay(
                    'Oops Something went wrong.',
                    2000,
                );
            }
            throw error; // Re-throw the error to propagate it if needed
        }
    }

    getOneConfig(value: any) {
        this.currentConfig.next(value);
    }

    async uploadImage(imageForm: any, type: any): Promise<string> {
        try {
            const url = `${environment.url}api/v1/web-config/${this.pressroomId}/${type}`;

            const response: any = await firstValueFrom(
                this.http.post(url, imageForm),
            );

            this.snackBar.showMessageWithDelay(
                `Successfully uploaded ${type} file.`,
                2000,
            );

            return response.image_URL; // Assuming the response contains image_URL
        } catch (error) {
            if (error instanceof HttpErrorResponse) {
                const errorMessage = `Failed to upload ${type} file`;
                this.snackBar.showMessageWithDelay(errorMessage, 2000);
            } else {
                this.snackBar.showMessageWithDelay(
                    'Oops Something went wrong.',
                    2000,
                );
            }
            throw error; // Re-throw the error to propagate it if needed
        }
    }
}
