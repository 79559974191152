import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, Meta } from '@angular/platform-browser';
import {
    APP_INITIALIZER,
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    VERSION,
} from '@angular/core';
import {
    HTTP_INTERCEPTORS,
    HttpClient,
    provideHttpClient,
    withInterceptorsFromDi,
} from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LayoutComponent } from './layout/layout.component';
import { MaterialModule } from './material.module';
import { NavigationComponent } from './layout/navigation/navigation.component';
import { PageNotFoundComponent } from './layout/page-not-found/page-not-found.component';
import { LoginComponent } from './login/login.component';
import { ComponentsModule } from './components/components.module';
import { AppService } from './app.service';
import { LocalStorageService } from './shared/services/local-storage.service';
import { WINDOW_INJECTION_TOKEN } from './shared/tokens/window.injection.token';
import { TokenInterceptor } from './auth/token.interceptor';
import { NgImageSliderModule } from 'ng-image-slider';
import { FontPickerModule } from 'ngx-font-picker';
import { FONT_PICKER_CONFIG } from 'ngx-font-picker';
import { FontPickerConfigInterface } from 'ngx-font-picker';
import { RecaptchaModule } from 'ng-recaptcha-2';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { registerLocaleData } from '@angular/common';

import localees from '@angular/common/locales/es';
import localefi from '@angular/common/locales/fi';
import localeen from '@angular/common/locales/en';
import localenl from '@angular/common/locales/nl';
import localefr from '@angular/common/locales/fr';
import localezh_Hans from '@angular/common/locales/zh-Hans';
import localezh_Hant from '@angular/common/locales/zh-Hant';
import localede from '@angular/common/locales/de';
import localeja from '@angular/common/locales/ja';
import localenn from '@angular/common/locales/nn';
import localeda from '@angular/common/locales/da';
import localenb from '@angular/common/locales/nb';
import localept from '@angular/common/locales/pt';
import localesv from '@angular/common/locales/sv';
import { SiteComponent } from './site/site.component';
import { HomeComponent } from './site/home/home.component';
import { NewsComponent } from './site/news/news.component';
import { GalleryComponent } from './site/gallery/gallery.component';
import { ArticleComponent } from './site/article/article.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FeedComponent } from './site/feed/feed.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AlbumsComponent } from './components/albums/albums.component';
import { ArticlePortfolioComponent } from './site/article-portfolio/article-portfolio.component';
import { FutureStoryComponent } from './site/future-story/future-story.component';
import { AppInitService } from './app.init.service';
import { SanitizeHtmlModule } from './safe.pipe.module';
import { SubscribeComponent } from './site/subscribe/subscribe.component';

registerLocaleData(localees, 'es');
registerLocaleData(localefi, 'fi');
registerLocaleData(localeen, 'en');
registerLocaleData(localenl, 'nl');
registerLocaleData(localefr, 'fr');
registerLocaleData(localezh_Hans, 'zh-Hans');
registerLocaleData(localezh_Hant, 'zh-Hant');
registerLocaleData(localede, 'de');
registerLocaleData(localeja, 'ja');
registerLocaleData(localept, 'pt');
registerLocaleData(localesv, 'sv');
registerLocaleData(localenn, 'nn');
registerLocaleData(localeda, 'da');
registerLocaleData(localenb, 'nb');

const DEFAULT_FONT_PICKER_CONFIG: FontPickerConfigInterface = {
    //Google font api key only for Blumepress domain...Not added in actions
    apiKey: 'AIzaSyBozXsrLIEafXWMX5iouAXaUZzvddx0jcs',
};

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function initializeApp(appInitService: AppInitService) {
    return () => appInitService.initApp();
}
@NgModule({
    declarations: [
        AppComponent,
        LayoutComponent,
        NavigationComponent,
        PageNotFoundComponent,
        LoginComponent,
        SiteComponent,
        HomeComponent,
        NewsComponent,
        GalleryComponent,
        ArticleComponent,
        FeedComponent,
        ArticlePortfolioComponent,
        FutureStoryComponent,
        SubscribeComponent,
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        BrowserModule,
        ComponentsModule,
        BrowserAnimationsModule,
        FormsModule,
        MaterialModule,
        AppRoutingModule,
        ReactiveFormsModule,
        NgxPaginationModule,
        NgImageSliderModule,
        FontPickerModule,
        NgxSkeletonLoaderModule,
        SanitizeHtmlModule,
        RecaptchaModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AppInitService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        {
            provide: FONT_PICKER_CONFIG,
            useValue: DEFAULT_FONT_PICKER_CONFIG,
        },
        Meta,
        AppService,
        LocalStorageService,
        { provide: WINDOW_INJECTION_TOKEN, useValue: window },
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
export class AppModule {}
