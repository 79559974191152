<div class="container">
    <h3>Reset Password</h3>
    <form [formGroup]="resetPasswordForm">
        <mat-form-field class="formControl" appearance="outline">
            <mat-label>Current Password</mat-label>
            <input
                matInput
                type="password"
                placeholder="Enter Current Password"
                formControlName="currentPassword"
            />
        </mat-form-field>
        <mat-form-field class="formControl" appearance="outline">
            <mat-label>New Password</mat-label>
            <input
                matInput
                type="password"
                placeholder="Enter New Password"
                formControlName="newPassword"
            />
        </mat-form-field>
        <mat-form-field class="formControl" appearance="outline">
            <mat-label>Re-type New Password</mat-label>
            <input
                matInput
                type="password"
                placeholder="Re-type New Password"
                formControlName="retypeNewPassword"
            />
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions class="dialogActions">
    <button
        mat-button
        style="background-color: #131313"
        color="primary"
        (click)="onSubmit()"
        cdkFocusInitial
    >
        Update Password
    </button>
    <button mat-button color="warn" (click)="onCancel()" *ngIf="disableCancel">
        Cancel
    </button>
</div>
